/**
 * action types
 */

export const ADD_TO_LAST_SEEN = 'ADD_TO_LAST_SEEN'

/**
 * action creators
 */

/**
 * @param {String} id
 */
export function addToLastSeen(id) {
  return {
    type: ADD_TO_LAST_SEEN,
    id,
  }
}
