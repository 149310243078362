import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withPrismicProductData from '../../hocs/withPrismicProductData'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { Translate } from 'react-localize-redux'
import removeFromCartSrc from '../../icons/removeFromCart.svg'
import qtyDownSrc from '../../icons/qtyDown.svg'
import qtyUpSrc from '../../icons/qtyUp.svg'
import { getFormattedPrice } from '../../utils/getFormattedPrice'

const globalDiscountPct = parseInt(
  process.env.GATSBY_GLOBAL_DISCOUNT_PCT || '0',
  10
)

const Label = ({ children }) => (
  <div className="mb-2 font-600 text-2xs uppercase tracking-widest opacity-50">
    {children}
  </div>
)

const Button = ({ children, ...props }) => (
  <button
    type="button"
    className="focus:outline-none focus:opacity-75"
    {...props}
  >
    {children}
  </button>
)

class LineItem extends Component {
  componentDidMount() {
    const { available } = this.props
    if (!available) {
      this.removeFromCart()
    }
  }

  removeFromCart = () => {
    const { removeLineItemInCart, shopifyId } = this.props
    removeLineItemInCart(shopifyId)
  }

  decrementQuantity = () => {
    const { updateQuantityInCart, shopifyId, quantity } = this.props
    const updatedQuantity = quantity - 1
    updateQuantityInCart(shopifyId, updatedQuantity)
  }

  incrementQuantity = () => {
    const { updateQuantityInCart, shopifyId, quantity } = this.props
    const updatedQuantity = quantity + 1
    updateQuantityInCart(shopifyId, updatedQuantity)
  }

  render() {
    const { title, image, pagePath, categoryName, price, quantity } = this.props
    const heroImage = getImage(image)
    return (
      <div className="relative">
        <div className="shadow-sm bg-almond border-b border-white py-6 px-5 xs:px-6">
          <div className="flex">
            <div className="w-20 h-20 overflow-hidden border-2 border-white">
              <GatsbyImage image={heroImage} alt="" />
            </div>
            <div className="pl-4">
              <Label>
                <Translate id="product" />
              </Label>
              <div className="font-500 leading-tight text-xl">
                <Link to={`/${pagePath}`}>{title}</Link>
              </div>
              <div className="opacity-75 text-sm">{categoryName}</div>
            </div>
            <div className="ml-auto">
              <Button onClick={this.removeFromCart}>
                <img src={removeFromCartSrc} alt="" />
              </Button>
            </div>
          </div>
          <div className="flex mt-4">
            <div>
              <Label>
                <Translate id="quantity" />
              </Label>
              <div className="flex items-center">
                <Button onClick={this.decrementQuantity}>
                  <img src={qtyDownSrc} alt="" />
                </Button>
                <span className="mx-2 font-500">{quantity}</span>
                <Button
                  className="pointer-events-none opacity-50"
                  // onClick={this.incrementQuantity}
                >
                  <img src={qtyUpSrc} alt="" />
                </Button>
              </div>
            </div>
            <div className="ml-auto text-right">
              <Label>
                <Translate id="price" />
              </Label>
              {globalDiscountPct > 0 &&
              !(
                title?.toLowerCase().includes('gift card') ||
                title?.toLowerCase().includes('tarjeta regalo')
              ) ? (
                <div className="flex font-sans">
                  <div className="line-through mr-2 text-terracotta">
                    {getFormattedPrice(price)}
                  </div>
                  <div>
                    {getFormattedPrice(
                      parseFloat(price) -
                        (globalDiscountPct / 100) * parseFloat(price)
                    )}
                  </div>
                </div>
              ) : (
                <div className="font-sans">
                  {getFormattedPrice(price * quantity)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

LineItem.propTypes = {
  available: PropTypes.bool.isRequired,
  shopifyId: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  updateQuantityInCart: PropTypes.func.isRequired,
  removeLineItemInCart: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
  pagePath: PropTypes.string.isRequired,
}

export default withPrismicProductData(LineItem)
