import React from 'react'
import PropTypes from 'prop-types'

const InstagramIconSolid = ({ color }) => {
  return (
    <svg fill="none" height="18" viewBox="0 0 18 18" width="18">
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="m13 1h-8c-2.20914 0-4 1.79086-4 4v8c0 2.2091 1.79086 4 4 4h8c2.2091 0 4-1.7909 4-4v-8c0-2.20914-1.7909-4-4-4z" />
        <path d="m12.2052 8.49624c.0988.6658-.015 1.34577-.325 1.94326-.31.5974-.8004 1.0819-1.4017 1.3845-.60118.3026-1.28251.4079-1.94704.301s-1.27842-.4207-1.75435-.8966c-.47594-.476-.78969-1.0899-.89662-1.75439-.10693-.66453-.0016-1.34585.30101-1.94707.3026-.60122.78708-1.09171 1.38452-1.40171s1.27742-.42372 1.94321-.32499c.67917.10071 1.30787.41717 1.79337.90265.4855.48547.8019 1.11421.9026 1.79335z" />
        <path d="m13.4062 4.60156h.0101" />
      </g>
    </svg>
  )
}

InstagramIconSolid.propTypes = {
  color: PropTypes.string,
}

InstagramIconSolid.defaultProps = {
  color: '#2D2D3F',
}

export default InstagramIconSolid
