import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import withMainNavData from '../../hocs/withMainNavData'
import MainHeaderBrand from '../MainHeaderBrand/MainHeaderBrand'
import InstagramIconSolid from '../../icons/InstagramIconSolid'
import GlobeIcon from '../../icons/GlobeIcon'
import FacebookIcon from '../../icons/FacebookIcon'
import PinterestIcon from '../../icons/PinterestIcon'
import Container from '../Container/Container'

const LangNav = ({ translations }) => (
  <ul className="mb-10 sm:mb-0 flex justify-center md:justify-end md:text-sm lg:text-base 2xl:text-lg 2xl:tracking-tight font-sans-regular">
    <li className="self-center">
      <GlobeIcon color="#EEBFB2" />
    </li>
    {translations.map(({ nativeName, path }, i) => (
      <li key={`${nativeName}${path}${i}`} className="ml-3">
        <Link to={path === '/' ? '/' : `/${path}`}>{nativeName}</Link>
      </li>
    ))}
  </ul>
)

const SocialMedia = ({ links }) => (
  <ul className="leading-loose flex text-xs sm:text-sm lg:text-base 2xl:text-lg 2xl:tracking-tight font-sans-regular">
    {links.map(({ icon, name, link }, i) => (
      <li key={i} className="mr-3 md:mr-6">
        <a
          href={link}
          target="_blank"
          rel="noreferrer noopener"
          className="flex items-center"
        >
          <div className="md:mr-2">{icon}</div>
          <span className="hidden md:block">{name}</span>
        </a>
      </li>
    ))}
    <li className="ml-auto">
      <a href="mailto:info@cabuyahandmade.com">info@cabuyahandmade.com</a>
    </li>
  </ul>
)

const Nav = ({ items }) => (
  <ul className="leading-loose lg:leading-snug font-sans-regular text-sm mb-6 lg:mb-12 xl:mb-16">
    {items.map(({ link, name }, i) => {
      let text = name
      let content = <span>{text}</span>

      if (link && link.document) {
        const {
          data: {
            title: { text: title },
          },
          fields: { pagePath },
        } = link.document

        if (!name) text = title

        content = <Link to={`/${pagePath}`}>{text}</Link>
      }

      return <li key={i}>{content}</li>
    })}
  </ul>
)

const Copyright = () => (
  <div className="opacity-50 mt-6 text-xs font-sans-regular">
    Cabuya Handmade © {new Date().getFullYear()}
  </div>
)

const Footer = ({
  translations,
  navData,
  socialMedia,
  layoutHasBottomAlert,
}) => {
  if (!translations) return null

  const {
    data: { footerColA, footerColB },
  } = navData

  return (
    <div
      className={`bg-white-pure${
        layoutHasBottomAlert ? ' pb-16 sm:pb-10' : ''
      }`}
    >
      <Container hasMarginBottom={false}>
        <div className="lg:flex flex-wrap pt-10 xl:pt-16 pb-6">
          <div className="mb-10 lg:mb-0 lg:w-1/4 flex lg:flex-col justify-center lg:justify-between">
            <MainHeaderBrand />
            <div className="hidden lg:block">
              <Copyright />
            </div>
          </div>
          <div className="sm:hidden">
            <LangNav translations={translations} />
          </div>
          <div className="lg:w-3/4">
            <div className="2sm:flex">
              <div className="2sm:w-1/2 sm:w-1/3">
                <Nav items={footerColA} />
              </div>
              <div className="2sm:w-1/2 sm:w-1/3">
                <Nav items={footerColB} />
              </div>
              <div className="hidden sm:block w-1/3">
                <LangNav translations={translations} />
              </div>
            </div>
            <SocialMedia links={socialMedia} />
          </div>
          <div className="lg:hidden">
            <Copyright />
          </div>
        </div>
      </Container>
    </div>
  )
}

Footer.propTypes = {
  translations: PropTypes.array,
  socialMedia: PropTypes.array,
  layoutHasBottomAlert: PropTypes.bool,
}

Footer.defaultProps = {
  socialMedia: [
    {
      icon: <InstagramIconSolid color="#EEBFB2" />,
      name: 'Instagram',
      link: 'https://www.instagram.com/cabuyahandmade',
    },
    {
      icon: <FacebookIcon color="#EEBFB2" />,
      name: 'Facebook',
      link: 'https://www.facebook.com/cabuyahandmade',
    },
    {
      icon: <PinterestIcon color="#EEBFB2" />,
      name: 'Pinterest',
      link: 'https://www.pinterest.es/cabuyashop',
    },
  ],
  layoutHasBottomAlert: false,
}

export default withMainNavData(Footer)
