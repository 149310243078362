import { SET_SHOPIFY_CLIENT } from '../actions/setShopifyClient'

export function shopifyClient(state = null, action) {
  switch (action.type) {
    case SET_SHOPIFY_CLIENT:
      return action.client
    default:
      return state
  }
}
