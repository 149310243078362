/**
 * action type
 */

export const SET_SHOPIFY_CLIENT = 'SET_SHOPIFY_CLIENT'

/**
 * action creators
 */

/**
 * @param {Object} client
 */
export function setShopifyClient(client) {
  return {
    type: SET_SHOPIFY_CLIENT,
    client,
  }
}
