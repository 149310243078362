import React from 'react'
import PropTypes from 'prop-types'

import ShoppingCartIcon from '../../icons/ShoppingCartIcon'

const ShowCart = ({ numOfItemsInCart, handleCartOpen, isLight }) => {
  return (
    <button
      type="button"
      className="h-16 pr-6 pl-4 sm:pl-6 xl:px-8 flex justify-center items-center focus:outline-none lg:h-24"
      onClick={handleCartOpen}
    >
      <ShoppingCartIcon color={isLight ? '#fff' : '#2D2D3F'} />
      {numOfItemsInCart > 0 && (
        <span
          className="ml-2 inline-block rounded-full bg-salmon w-5 h-5 text-xs"
          style={{ lineHeight: '1.25rem' }}
        >
          {numOfItemsInCart}
        </span>
      )}
    </button>
  )
}

ShowCart.propTypes = {
  numOfItemsInCart: PropTypes.number.isRequired,
  handleCartOpen: PropTypes.func.isRequired,
}

ShowCart.defaultProps = {
  isLight: false,
}

export default ShowCart
