import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import clearIconSrc from '../../icons/clearWhite.svg'
import { overlay, inner } from './Modal.module.scss'
import Border from '../Border/Border'

const Modal = ({
  children,
  isOpen,
  onRequestClose,
  showBorders,
  bgClassName,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      closeTimeoutMS={600}
      overlayClassName={`${overlay} fixed inset-0 flex items-center justify-center bg-black-overlay font-sans`}
      className="focus:outline-none"
    >
      <>
        <div className={inner}>
          {showBorders && (
            <div className="-mb-1">
              <Border name="gray100BorderB" />
            </div>
          )}
          <div className={`${bgClassName} py-12 relative`}>{children}</div>
          {showBorders && (
            <div className="-mt-1">
              <Border name="gray100BorderC" />
            </div>
          )}
        </div>
        <div className="absolute top-0 right-0">
          <button
            type="button"
            onClick={() => onRequestClose()}
            className="w-16 h-16 flex items-center justify-center opacity-50 focus:outline-none focus:opacity-100"
          >
            <img src={clearIconSrc} alt="" />
          </button>
        </div>
      </>
    </ReactModal>
  )
}

Modal.propTypes = {
  children: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  bgClassName: PropTypes.string,
}

Modal.defaultProps = {
  showBorders: true,
  bgClassName: 'bg-gray-100',
}

export default Modal
