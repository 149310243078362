import React from 'react'
import PropTypes from 'prop-types'
import gray100BorderA from './images/gray100BorderA.svg'
import gray100BorderB from './images/gray100BorderB.svg'
import gray100BorderC from './images/gray100BorderC.svg'
import gray200BorderA from './images/gray200BorderA.svg'
import gray200BorderB from './images/gray200BorderB.svg'
import gray200BorderC from './images/gray200BorderC.svg'
import gray200BorderD from './images/gray200BorderD.svg'
import salmonBorderA from './images/salmonBorderA.svg'

const borders = {
  gray100BorderA,
  gray100BorderB,
  gray100BorderC,
  gray200BorderA,
  gray200BorderB,
  gray200BorderC,
  gray200BorderD,
  salmonBorderA,
}

const Border = ({ name }) => {
  return <img src={borders[name]} alt="" />
}

Border.propTypes = {
  name: PropTypes.oneOf([
    'gray100BorderA',
    'gray100BorderB',
    'gray100BorderC',
    'gray200BorderA',
    'gray200BorderB',
    'gray200BorderC',
    'gray200BorderD',
    'salmonBorderA',
  ]).isRequired,
}

export default Border
