import React from 'react'
import PropTypes from 'prop-types'

const FacebookIcon = ({ color }) => {
  return (
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none">
      <path
        d="M8.8 0H6.4C5.33913 0 4.32172 0.421427 3.57157 1.17157C2.82143 1.92172 2.4 2.93913 2.4 4V6.4H0V9.6H2.4V16H5.6V9.6H8L8.8 6.4H5.6V4C5.6 3.78783 5.68429 3.58434 5.83431 3.43431C5.98434 3.28429 6.18783 3.2 6.4 3.2H8.8V0Z"
        fill={color}
      />
    </svg>
  )
}

FacebookIcon.propTypes = {
  color: PropTypes.string,
}

FacebookIcon.defaultProps = {
  color: '#2D2D3F',
}

export default FacebookIcon
