import React from 'react'
import PropTypes from 'prop-types'

import smallSrc from './assets/mainHeaderBrand.svg'
import smallSrcLight from './assets/mainHeaderBrandLight.svg'
import largeSrc from './assets/mainHeaderBrandLarge.svg'
import largeSrcLight from './assets/mainHeaderBrandLargeLight.svg'

import { byLang } from '../../constants/langs'
import { Link } from 'gatsby'
import { large } from './MainHeaderBrand.module.scss'

const MainHeaderBrand = ({ lang, isLight }) => {
  let dest = null
  if (lang) {
    dest = byLang[lang].pathPrefix
  }
  const brand = (
    <>
      <img
        src={isLight ? smallSrcLight : smallSrc}
        alt="Cabuya Handmade"
        className="lg:hidden"
      />
      <img
        src={isLight ? largeSrcLight : largeSrc}
        alt="Cabuya Handmade"
        className={`hidden lg:block ${large}`}
      />
    </>
  )

  return lang ? (
    <Link to={`/${dest}`} className="block">
      {brand}
    </Link>
  ) : (
    <>{brand}</>
  )
}

MainHeaderBrand.propTypes = {
  lang: PropTypes.string,
  isLight: PropTypes.bool,
}

MainHeaderBrand.defaultProps = {
  isLight: false,
}

export default MainHeaderBrand
