import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { overlay, visible } from './PageOverlay.module.scss'

const PageOverlay = ({ pageOverlayIsVisible }) => {
  return (
    <div
      key="PageOverlay"
      className={[
        'flex',
        'items-center',
        'justify-center',
        'fixed',
        'inset-0',
        'bg-black',
        overlay,
        pageOverlayIsVisible ? visible : '',
      ].join(' ')}
    />
  )
}

PageOverlay.propTypes = {
  pageOverlayIsVisible: PropTypes.bool,
}

const mapStateToProps = ({ pageOverlayIsVisible }) => {
  return { pageOverlayIsVisible }
}

export default connect(mapStateToProps)(PageOverlay)
