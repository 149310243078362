import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import Modal from 'react-modal'
import { StaticImage } from 'gatsby-plugin-image'

import LineItem from '../LineItem/LineItem'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
// import CartShippingInfo from '../CartShippingInfo/CartShippingInfo'

import clearIconSrc from '../../icons/clear.svg'

import {
  overlay,
  modalContent,
  innerWrap,
  updatingContainer,
  lineItems,
  emptyMessage,
  closeContainer,
  heading,
  close,
  totalContainer,
  totalGrid,
  totalLabel,
  totalValue,
  checkoutButton,
} from './Cart.module.scss'
import { getFormattedPrice } from '../../utils/getFormattedPrice'

const Cart = ({
  isOpen,
  updating,
  checkout,
  updateQuantityInCart,
  removeLineItemInCart,
  handleCartClose,
}) => {
  const [totalHeight, setTotalHeight] = useState(0)
  const webUrl = checkout?.webUrl || ''

  const handleTotalHeight = el => {
    if (!el) return
    const { height } = el.getBoundingClientRect()
    setTotalHeight(height)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCartClose}
      overlayClassName={overlay}
      className={modalContent}
      ariaHideApp={false}
      closeTimeoutMS={800}
    >
      <div className={innerWrap} style={{ paddingBottom: totalHeight }}>
        <div className={heading}>
          <Translate id="yourCart" />
        </div>
        {updating && (
          <div className={updatingContainer}>
            <LoadingSpinner />
          </div>
        )}
        {(checkout?.lineItems || []).length > 0 && !updating ? (
          <div className={lineItems}>
            {checkout.lineItems.map(line_item => {
              const { id, title, variant, quantity } = line_item
              const { price, sku, available } = variant
              return (
                <LineItem
                  available={available}
                  key={id.toString()}
                  shopifyId={id}
                  title={title}
                  price={price?.amount}
                  sku={sku}
                  quantity={quantity}
                  updateQuantityInCart={updateQuantityInCart}
                  removeLineItemInCart={removeLineItemInCart}
                />
              )
            })}
          </div>
        ) : (
          <>
            {!updating && (
              <div className={emptyMessage}>
                <Translate id="cartIsEmpty" />
                <div className="text-center select-none pointer-events-none">
                  <StaticImage
                    src="./assets/empty-cart.png"
                    alt=""
                    quality={100}
                    layout="constrained"
                    width={170}
                    placeholder="none"
                  />
                </div>
              </div>
            )}
          </>
        )}
        <div className={closeContainer}>
          <button type="button" onClick={handleCartClose} className={close}>
            <img src={clearIconSrc} alt="" />
          </button>
        </div>
      </div>
      {(checkout?.lineItems || []).length > 0 && (
        <div className={totalContainer} ref={handleTotalHeight}>
          <div className={totalGrid}>
            <div className={totalLabel}>Total</div>
            <div className={totalValue}>
              <span>{getFormattedPrice(checkout?.subtotalPrice?.amount)}</span>
            </div>
          </div>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href={webUrl} target="_blank" className={checkoutButton}>
            Checkout
          </a>
          {/* TODO */}
          {/* <CartShippingInfo /> */}
        </div>
      )}
    </Modal>
  )
}

Cart.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  updating: PropTypes.bool.isRequired,
  checkout: PropTypes.object.isRequired,
  handleCartClose: PropTypes.func.isRequired,
}

export default Cart
