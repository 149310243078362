import React, { useRef, useState, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Modal from '../Modal/Modal'
import NewsletterForm from '../NewsletterForm/NewsletterForm'

const cookieValue = 'newsletter_submitted=true'

const setStorage = () => {
  sessionStorage.setItem('newsletter_modal_shown', 'true')
}

const isModalShownInSession = () => {
  const storageItem = sessionStorage.getItem('newsletter_modal_shown')
  return !!storageItem && storageItem === 'true'
}

const isCookieSet = () => document.cookie.includes(cookieValue)

const hasScrolled = () => window.scrollY > window.innerHeight

const NewsletterModal = () => {
  const submitTimeout = useRef()
  const [isOpen, setIsOpen] = useState(false)

  const onSubmit = () => {
    submitTimeout.current = setTimeout(() => {
      setIsOpen(false)
    }, 1000)
  }

  const onScroll = () => {
    if (hasScrolled()) {
      setStorage()
      setIsOpen(true)
      window.removeEventListener('scroll', onScroll)
    }
  }

  useEffect(() => {
    if (!isCookieSet() && !isModalShownInSession()) {
      window.addEventListener('scroll', onScroll)
    }

    return () => {
      if (submitTimeout.current) clearTimeout(submitTimeout.current)
    }
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      showBorders={false}
      onRequestClose={() => {
        setIsOpen(false)
      }}
    >
      <>
        <div className="px-6 sm:px-8 md:px-16 lg:px-20 lg:pt-6 xl:px-24 xl:pb-4 relative z-10">
          <NewsletterForm onSubmit={onSubmit} />
        </div>
        <div className="absolute inset-0 pointer-events-none">
          <StaticImage
            src="./assets/newsletter-modal.png"
            alt=""
            className="static block select-none"
            objectFit="cover"
            imgStyle={{ width: '100%', height: '100%' }}
            style={{ width: '100%', height: '100%' }}
            quality={100}
          />
        </div>
      </>
    </Modal>
  )
}

export default NewsletterModal
