import React, { cloneElement, Component, createContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import withShopifyClient from '../hocs/withShopifyClient'
import { setMainNavVisibility } from '../actions/mainNavVisibility'
import { setShopifyClient } from '../actions/setShopifyClient'

import Cart from '../components/Cart/Cart'
import Footer from '../components/Footer/Footer'
import MainHeader from '../components/MainHeader/MainHeader'
import MainNav from '../components/MainNav/MainNav'
import NewsletterFooter from '../components/NewsletterFooter/NewsletterFooter'
import NewsletterModal from '../components/NewsletterModal/NewsletterModal'
import PageOverlay from '../components/PageOverlay/PageOverlay'
import TopMessage from '../components/TopMessage/TopMessage'

import '../styles/base.css'
import '../styles/webfonts.css'
import '../styles/styles.css'
import '../styles/swiper.css'

export const CheckoutContext = createContext()

class MainLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isCartOpen: false,
      updatingCart: false,
      checkout: { lineItems: [] },
    }
    this.handleCartClose = this.handleCartClose.bind(this)
    this.handleCartOpen = this.handleCartOpen.bind(this)
    this.addVariantToCart = this.addVariantToCart.bind(this)
    this.updateQuantityInCart = this.updateQuantityInCart.bind(this)
    this.removeLineItemInCart = this.removeLineItemInCart.bind(this)
  }

  componentDidMount() {
    const { client, setShopifyClient } = this.props
    const checkoutId = window.localStorage.getItem('checkoutId')

    setShopifyClient(client)

    if (checkoutId) {
      client.checkout
        .fetch(checkoutId)
        .then(res => {
          if (res.completedAt) {
            this.createCheckout()
            return
          }

          this.setState({
            checkout: res,
          })
        })
        .catch(() => {
          window.localStorage.removeItem('checkoutId')
          this.createCheckout()
        })
    } else {
      this.createCheckout()
    }
  }

  componentDidUpdate(prevProps) {
    const { location, hideNav } = this.props
    if (prevProps.location.pathname !== location.pathname) {
      hideNav()
    }
  }

  createCheckout() {
    this.props.client.checkout.create().then(res => {
      const { id } = res
      window.localStorage.setItem('checkoutId', id)
      this.setState({
        checkout: res,
      })
    })
  }

  addVariantToCart(variantId, quantity) {
    this.setState({
      isCartOpen: true,
      updatingCart: true,
    })

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }]
    const checkoutId = this.state.checkout.id

    return this.props.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then(res => {
        this.setState({
          checkout: res,
          updatingCart: false,
        })
      })
  }

  updateQuantityInCart(lineItemId, quantity) {
    const checkoutId = this.state.checkout.id
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ]

    return this.props.client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then(res => {
        this.setState({
          checkout: res,
        })
      })
  }

  removeLineItemInCart(lineItemId) {
    const checkoutId = this.state.checkout.id

    return this.props.client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then(res => {
        this.setState({
          checkout: res,
        })
      })
      .catch(() => {
        this.resetCheckout()
      })
  }

  resetCheckout() {
    window.localStorage.removeItem('checkoutId')
    this.createCheckout()
  }

  handleCartOpen() {
    this.setState({
      isCartOpen: true,
    })
  }

  handleCartClose() {
    this.setState({
      isCartOpen: false,
    })
  }

  render() {
    const { children, pageContext } = this.props
    const {
      translations = [
        { path: 'es/', nativeName: 'Español' },
        { path: '/', nativeName: 'English' },
      ],
      lang = 'en-gb',
      templateName,
    } = pageContext
    const { isCartOpen, checkout, updatingCart } = this.state

    if (!translations || !lang) return null

    const hasHero = templateName === 'Homepage' || templateName === 'Longform'

    // TODO: refactor using context provider
    const childProps = {
      addVariantToCart: this.addVariantToCart,
    }

    const getHreflang = nativeName => {
      switch (nativeName) {
        case 'Español':
          return 'es-ES'
        default:
          return 'x-default'
      }
    }

    return (
      <>
        <Helmet>
          {translations.map(({ nativeName, path }) => {
            const hreflang = getHreflang(nativeName)
            const href = `https://cabuyahandmade.com/${path}`
            return (
              <link
                key={nativeName}
                rel="alternate"
                hrefLang={hreflang}
                href={href}
              />
            )
          })}
        </Helmet>
        <div className="font-sans">
          <TopMessage />
          <MainHeader
            lang={lang}
            numOfItemsInCart={
              checkout && checkout.lineItems && checkout.lineItems.length
            }
            handleCartOpen={this.handleCartOpen}
            hasHero={hasHero}
            translations={translations}
          />
          <MainNav />
          <CheckoutContext.Provider value={{ checkout }}>
            {cloneElement(children, { ...childProps })}
          </CheckoutContext.Provider>
          <NewsletterFooter />
          <NewsletterModal />
          <Footer translations={translations} layoutHasBottomAlert />
          <Cart
            isOpen={isCartOpen}
            updating={updatingCart}
            checkout={checkout}
            handleCartClose={this.handleCartClose}
            updateQuantityInCart={this.updateQuantityInCart}
            removeLineItemInCart={this.removeLineItemInCart}
          />
          <PageOverlay />
        </div>
      </>
    )
  }
}

MainLayout.propTypes = {
  client: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    hideNav: () => dispatch(setMainNavVisibility(false)),
    setShopifyClient: client => dispatch(setShopifyClient(client)),
  }
}

export default withShopifyClient(connect(null, mapDispatchToProps)(MainLayout))
