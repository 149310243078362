const NAV_SLICE_TYPES = {
  ALPHA: 'item_1',
  BETA: 'item_2',
  GAMMA: 'item_3',
}

const getNavLinkData = navLinkData => {
  const {
    id,
    primary: {
      link: { document },
      name: nameValue,
    },
  } = navLinkData

  let name = null
  let link = null

  if (document) {
    const {
      data: {
        title: { text: title },
      },
      fields: { pagePath },
    } = document

    name = title
    link = pagePath
  }

  if (nameValue) {
    name = nameValue
  }

  return {
    id,
    name,
    link,
    children: [],
  }
}

export const buildNavDataTree = navData => {
  const {
    data: { body },
  } = navData

  return body.reduce((acc, curr, i) => {
    const { slice_type } = curr
    const navLinkData = getNavLinkData(curr)

    if (i === 0) {
      if (slice_type !== NAV_SLICE_TYPES.ALPHA) {
        console.warn('1st link should be at the top level')
        return acc
      }
      acc.push(navLinkData)
      return acc
    }

    switch (slice_type) {
      case NAV_SLICE_TYPES.BETA:
        acc[acc.length - 1].children.push(navLinkData)
        break
      case NAV_SLICE_TYPES.GAMMA: {
        const lastItemChildren = acc[acc.length - 1].children
        lastItemChildren[lastItemChildren.length - 1].children.push(navLinkData)
        break
      }
      default:
        acc.push(navLinkData)
        break
    }

    return acc
  }, [])
}
