// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/Category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/Contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-homepage-jsx": () => import("./../../../src/templates/Homepage.jsx" /* webpackChunkName: "component---src-templates-homepage-jsx" */),
  "component---src-templates-legal-page-jsx": () => import("./../../../src/templates/LegalPage.jsx" /* webpackChunkName: "component---src-templates-legal-page-jsx" */),
  "component---src-templates-longform-jsx": () => import("./../../../src/templates/Longform.jsx" /* webpackChunkName: "component---src-templates-longform-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/Product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-shop-jsx": () => import("./../../../src/templates/Shop.jsx" /* webpackChunkName: "component---src-templates-shop-jsx" */),
  "component---src-templates-subcategory-jsx": () => import("./../../../src/templates/Subcategory.jsx" /* webpackChunkName: "component---src-templates-subcategory-jsx" */)
}

