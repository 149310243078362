import React, { useRef, useState, useEffect } from 'react'
import { Translate } from 'react-localize-redux'

const messageAText = 'shippingFreeEu'
const messageBText = 'shippingFreeSpain'
const messageCText = 'shippingWorldwide'

const Text = ({ text, isHiding, isVisible }) => {
  const base =
    'flex items-center justify-center absolute inset-0 transform transition duration-500'

  let className

  if (isHiding) {
    className = `${base} opacity-0 -translate-y-2`
  } else if (!isVisible) {
    className = `${base} opacity-0 translate-y-2`
  } else {
    className = `${base} opacity-100 translate-y-0`
  }

  return (
    <div className={className}>
      <span>
        <Translate id={text} />
      </span>
    </div>
  )
}

const TopMessage = () => {
  const intervalRef = useRef()
  const showTimeoutRef = useRef()
  const hideTimeoutRef = useRef()
  const [currentMessage, setCurrentMessage] = useState(messageAText)
  const [isHiding, setIsHiding] = useState(true)
  const [isVisible, setIsVisible] = useState(true)
  const [backgroundClass, setBackgroundClass] = useState('bg-zinnwaldite')

  useEffect(() => {
    if (!window) return

    intervalRef.current = setInterval(() => {
      setIsHiding(true)
      hideTimeoutRef.current = setTimeout(() => {
        setIsVisible(false)
        setCurrentMessage(currMsg => {
          switch (currMsg) {
            case messageAText:
              return messageBText
            case messageBText:
              return messageCText
            default:
              return messageAText
          }
        })
      }, 500)
    }, 6000)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }

      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current)
      }
    }
  }, [])

  useEffect(() => {
    setIsHiding(false)

    setBackgroundClass(currBg => {
      if (currBg === 'bg-zinnwaldite') {
        return 'bg-harvest-gold'
      } else {
        return 'bg-zinnwaldite'
      }
    })

    showTimeoutRef.current = setTimeout(() => {
      setIsVisible(true)
    }, 500)

    return () => {
      if (showTimeoutRef.current) {
        clearTimeout(showTimeoutRef.current)
      }
    }
  }, [currentMessage])

  return (
    <div
      className={`relative overflow-hidden h-6 leading-none text-center text-2xs uppercase whitespace-no-wrap select-none transition duration-3000 z-10 ${backgroundClass}`}
    >
      <Text text={currentMessage} isHiding={isHiding} isVisible={isVisible} />
    </div>
  )
}

export default TopMessage
