import React from 'react'
import PropTypes from 'prop-types'

const GlobeIcon = ({ color }) => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20">
      <g stroke={color} strokeLinecap="round" strokeLinejoin="round">
        <path d="m10 19c4.9706 0 9-4.0294 9-9 0-4.97056-4.0294-9-9-9-4.97056 0-9 4.02944-9 9 0 4.9706 4.02944 9 9 9z" />
        <path d="m1 10h18" />
        <path d="m9.99844 1c2.25116 2.46452 3.53046 5.66283 3.59996 9-.0695 3.3372-1.3488 6.5355-3.59996 9-2.25115-2.4645-3.53048-5.6628-3.6-9 .06952-3.33717 1.34885-6.53548 3.6-9z" />
      </g>
    </svg>
  )
}

GlobeIcon.propTypes = {
  color: PropTypes.string,
}

GlobeIcon.defaultProps = {
  color: '#2D2D3F',
}

export default GlobeIcon
