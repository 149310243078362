import React from 'react'
import PropTypes from 'prop-types'

const ChevronDownCircle = ({ color }) => {
  return (
    <svg fill="none" height="18" viewBox="0 0 18 18" width="18">
      <g stroke={color}>
        <path
          d="m9 1c-4.41667 0-8 3.58333-8 8 0 4.4167 3.58333 8 8 8 4.4167 0 8-3.5833 8-8 0-4.41667-3.5833-8-8-8z"
          strokeMiterlimit="10"
        />
        <path
          d="m13 7.33203-4 3.99997-4-3.99997"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

ChevronDownCircle.propTypes = {
  color: PropTypes.string,
}

ChevronDownCircle.defaultProps = {
  color: '#2d2d3f',
}

export default ChevronDownCircle
