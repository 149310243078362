import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { useOnClickOutside } from '../../utils/useOnClickOutside'
import clearIconSrc from '../../icons/clear.svg'
import ChevronDown from '../../icons/ChevronDown'

const LANG_ABBRS = {
  'en-gb': 'en',
  'es-es': 'es',
}

const MainHeaderLangNav = ({ lang, translations, isLight }) => {
  const langNavRef = useRef(null)
  const [isActive, setIsActive] = useState(false)
  const langNavClassName = isActive
    ? 'translate-y-0 opacity-100'
    : '-translate-y-full opacity-0'

  useOnClickOutside(langNavRef, () => {
    setIsActive(false)
  })

  if (!translations || !lang) return null

  return (
    <>
      <div className="flex items-center h-16 lg:h-24">
        <button
          type="button"
          className="flex items-center uppercase focus:outline-none text-xs tracking-wide"
          onClick={() => {
            setIsActive(true)
          }}
        >
          <span>{LANG_ABBRS[lang]}</span>
          <div className="ml-2">
            <ChevronDown color={isLight ? '#f9f7f5' : '#2D2D3F'} />
          </div>
        </button>
      </div>
      <div
        className={`fixed top-0 right-0 left-0 2md:left-auto h-16 2md:h-20 2md:w-72 flex items-center transition duration-500 transform ${langNavClassName}`}
        ref={langNavRef}
      >
        <div className="absolute inset-0 flex items-center justify-center 2md:justify-start px-6 bg-almond text-black z-10">
          {translations.map(({ nativeName, path }, i) => (
            <div key={`${nativeName}${path}${i}`} className="mx-3">
              <Link
                to={path === '/' ? '/' : `/${path}`}
                onClick={() => {
                  setIsActive(false)
                }}
              >
                {nativeName}
              </Link>
            </div>
          ))}
        </div>
        <button
          type="button"
          className="ml-auto w-16 h-16 flex items-center justify-center opacity-50 focus:outline-none focus:opacity-100 relative z-20"
          onClick={() => {
            setIsActive(false)
          }}
        >
          <img src={clearIconSrc} alt="" />
        </button>
      </div>
    </>
  )
}

MainHeaderLangNav.propTypes = {
  lang: PropTypes.string,
  translations: PropTypes.array,
  isLight: PropTypes.bool,
}

MainHeaderLangNav.defaultProps = {
  isLight: false,
}

export default MainHeaderLangNav
