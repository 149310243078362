exports.allLangs = ['es-es', 'en-gb']

exports.byLang = {
  'es-es': {
    pathPrefix: 'es',
    nativeName: 'Español',
  },
  'en-gb': {
    pathPrefix: '',
    nativeName: 'English',
  },
}
