import React from 'react'
import { withLocalize } from 'react-localize-redux'
import { StaticImage } from 'gatsby-plugin-image'
import NewsletterForm from '../NewsletterForm/NewsletterForm'

const NewsletterFooter = () => {
  return (
    <div className="relative py-px">
      <div className="absolute inset-0 flex justify-center items-center px-6">
        <div className="w-full">
          <NewsletterForm />
        </div>
      </div>
      <div className="h-64 xl:my-12 2xl:my-16" />
      <div className="hidden md:block">
        <div
          className="absolute inset-0 pointer-events-none"
          style={{ zIndex: '-1' }}
        >
          <StaticImage
            src="./assets/newsletter-form-bg.png"
            alt=""
            className="static block select-none"
            objectFit="cover"
            imgStyle={{ width: '100%', height: '100%' }}
            style={{ width: '100%', height: '100%' }}
            quality={100}
          />
        </div>
      </div>
      <div className="md:hidden">
        <div
          className="absolute inset-0 pointer-events-none"
          style={{ zIndex: '-1' }}
        >
          <StaticImage
            src="./assets/newsletter-form-bg-mobile.png"
            alt=""
            className="static block select-none"
            objectFit="cover"
            imgStyle={{ width: '100%', height: '100%' }}
            style={{ width: '100%', height: '100%' }}
            quality={100}
          />
        </div>
      </div>
    </div>
  )
}

export default withLocalize(NewsletterFooter)
