/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import withMainNavData from '../../hocs/withMainNavData'

import { useOnClickOutside } from '../../utils/useOnClickOutside'
import { buildNavDataTree } from '../../utils/buildNavDataTree'

import {
  subnav,
  visible,
  light,
  hasActiveSubnav,
  subnavIsVisible,
} from './DesktopMainNav.module.scss'
import ChevronDown from '../../icons/ChevronDown'

const NavTopListItem = ({
  children: itemChildren,
  link,
  name,
  isLight,
  setSubnavVisible,
}) => {
  const subnavRef = useRef(null)
  const listItemRef = useRef(null)

  const showSubnav = () => {
    if (!subnavRef.current) return

    if (subnavRef.current.classList.contains('is-animating')) return

    setSubnavVisible(true)

    listItemRef.current.classList.add(hasActiveSubnav)
    subnavRef.current.classList.add('is-animating')
    subnavRef.current.classList.remove('hidden')
    setTimeout(() => {
      if (subnavRef.current) subnavRef.current.classList.add(visible)
    }, 10)

    setTimeout(() => {
      if (subnavRef.current) subnavRef.current.classList.remove('is-animating')
    }, 310)
  }

  const hideSubnav = () => {
    if (!subnavRef.current) return

    if (subnavRef.current.classList.contains('is-animating')) return

    setSubnavVisible(false)

    listItemRef.current.classList.remove(hasActiveSubnav)
    subnavRef.current.classList.add('is-animating')
    subnavRef.current.classList.remove(visible)

    setTimeout(() => {
      if (subnavRef.current) {
        subnavRef.current.classList.add('hidden')
        subnavRef.current.classList.remove('is-animating')
      }
    }, 300)
  }

  useOnClickOutside(listItemRef, hideSubnav)

  const text = (
    <div className="flex items-center">
      <span>{name}</span>
      {itemChildren.length > 0 && (
        <div className="ml-2">
          <ChevronDown color={isLight ? '#f9f7f5' : '#2D2D3F'} />
        </div>
      )}
    </div>
  )

  const button = link ? (
    <Link to={`/${link}`} className="relative z-10" onMouseEnter={showSubnav}>
      {text}
    </Link>
  ) : (
    <button
      type="button"
      className="relative z-10 uppercase"
      onClick={showSubnav}
      onMouseEnter={showSubnav}
    >
      {text}
    </button>
  )

  return (
    <li
      ref={listItemRef}
      className="relative mr-8 transition duration-500"
      onMouseLeave={hideSubnav}
    >
      {button}
      {itemChildren.length > 0 && (
        <ul
          ref={subnavRef}
          className={`hidden absolute w-64 pt-3 pb-4 2xl:pb-5 font-sans-regular leading-loose tracking-normal normal-case text-sm xl:text-base ${subnav}`}
        >
          {itemChildren.map(({ children, id, link, name }) => {
            const content = link ? (
              <Link to={`/${link}`}>{name}</Link>
            ) : (
              <span>{name}</span>
            )
            return (
              <li key={id} className="relative">
                {content}
                {children.length > 0 && (
                  <ul className="text-2xs xl:text-xs mt-1 mb-4">
                    {children.map(
                      ({ id: childId, link: childLink, name: childName }) => {
                        const childContent = childLink ? (
                          <Link to={`/${childLink}`}>{childName}</Link>
                        ) : (
                          <span>{childName}</span>
                        )
                        return <li key={childId}>{childContent}</li>
                      }
                    )}
                  </ul>
                )}
              </li>
            )
          })}
        </ul>
      )}
    </li>
  )
}

const NavTopList = ({ items, isLight }) => {
  const [subnavVisible, setSubnavVisible] = useState(false)
  const getUlClassName = () => {
    const base =
      'pl-12 3xl:pl-8 h-16 lg:h-24 flex items-center uppercase text-xs tracking-wide relative z-10'
    return `${base}${isLight ? ` ${light}` : ''}${
      subnavVisible ? ` ${subnavIsVisible}` : ''
    }`
  }

  return (
    <ul className={getUlClassName()}>
      {items.map(({ children, id, link, name }) => (
        <NavTopListItem
          key={id}
          children={children}
          link={link}
          name={name}
          isLight={isLight}
          setSubnavVisible={setSubnavVisible}
        />
      ))}
    </ul>
  )
}

const DesktopMainNav = ({ navData, isLight }) => {
  const items = buildNavDataTree(navData)

  return <NavTopList items={items} isLight={isLight} />
}

DesktopMainNav.propTypes = {
  navData: PropTypes.object,
  isLight: PropTypes.bool,
}

export default withMainNavData(DesktopMainNav)
