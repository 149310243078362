import React from 'react'
import PropTypes from 'prop-types'

const ShoppingCartIcon = ({ color }) => {
  return (
    <svg
      fill="none"
      height="21"
      viewBox="0 0 22 21"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke={color} strokeLinecap="round" strokeLinejoin="round">
        <path
          d="m8.24058 20.0007c.49962 0 .90464-.405.90464-.9047 0-.4996-.40502-.9046-.90464-.9046s-.90464.405-.90464.9046c0 .4997.40502.9047.90464.9047z"
          fill={color}
        />
        <path
          d="m18.182 20.0007c.4996 0 .9046-.405.9046-.9047 0-.4996-.405-.9046-.9046-.9046s-.9047.405-.9047.9046c0 .4997.4051.9047.9047.9047z"
          fill={color}
        />
        <path d="m1 1h3.61856l2.42444 12.1131c.08273.4165.30931.7907.64008 1.0569.33077.2663.74465.4078 1.1692.3996h8.79312c.4245.0082.8384-.1333 1.1692-.3996.3308-.2662.5574-.6404.6401-1.0569l1.4474-7.58989h-15.37889" />
      </g>
    </svg>
  )
}

ShoppingCartIcon.propTypes = {
  color: PropTypes.string,
}

ShoppingCartIcon.defaultProps = {
  color: '#2D2D3F',
}

export default ShoppingCartIcon
