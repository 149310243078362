import React from 'react'
import { spinner } from './LoadingSpinner.module.scss'
import spinnerImg from '../../icons/spinner.svg'

const LoadingSpinner = () => {
  return (
    <div className={spinner}>
      <img src={spinnerImg} alt="Loading" />
    </div>
  )
}

export default LoadingSpinner
