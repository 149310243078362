import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { setMainNavVisibility } from '../../actions/mainNavVisibility'
import ShowMainNavIcon from '../../icons/ShowMainNavIcon'

const ShowMainNav = ({ showNav, isLight }) => {
  return (
    <div className="relative z-10">
      <button
        type="button"
        className="h-16 w-16 flex justify-center items-center focus:outline-none lg:w-24 lg:h-24"
        onClick={() => showNav()}
      >
        <ShowMainNavIcon color={isLight ? '#f9f7f5' : '#2D2D3F'} />
      </button>
    </div>
  )
}

ShowMainNav.propTypes = {
  showNav: PropTypes.func.isRequired,
  isLight: PropTypes.bool,
}

ShowMainNav.defaultProps = {
  isLight: false,
}

const mapDispatchToProps = dispatch => {
  return {
    showNav: () => dispatch(setMainNavVisibility(true)),
  }
}

export default connect(null, mapDispatchToProps)(ShowMainNav)
