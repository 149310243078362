import React from 'react'
import PropTypes from 'prop-types'
// import Headroom from 'react-headroom'

import ShowMainNav from '../ShowMainNav/ShowMainNav'
import ShowCart from '../ShowCart/ShowCart'
import MainHeaderBrand from '../MainHeaderBrand/MainHeaderBrand'
import DesktopMainNav from '../DesktopMainNav/DesktopMainNav'
import MainHeaderLangNav from '../MainHeaderLangNav/MainHeaderLangNav'

import { container, inner } from './MainHeader.module.scss'

const MainHeader = ({
  lang,
  numOfItemsInCart,
  handleCartOpen,
  hasHero,
  translations,
}) => {
  return (
    <div className={container}>
      <div
        className={`relative flex justify-center z-50 ${inner}${
          hasHero ? ' text-white' : ''
        }`}
      >
        <div className="w-full max-w-8xl relative h-16 flex justify-between lg:h-32">
          <div className="lg:hidden">
            <ShowMainNav isLight={hasHero} />
          </div>
          <div className="hidden lg:block">
            <DesktopMainNav isLight={hasHero} />
          </div>
          <div className="absolute inset-0 flex items-center justify-center">
            <MainHeaderBrand lang={lang} isLight={hasHero} />
          </div>
          <div className="flex relative z-10">
            <MainHeaderLangNav
              lang={lang}
              isLight={hasHero}
              translations={translations}
            />
            <ShowCart
              numOfItemsInCart={numOfItemsInCart}
              handleCartOpen={handleCartOpen}
              isLight={hasHero}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

MainHeader.propTypes = {
  lang: PropTypes.string.isRequired,
  numOfItemsInCart: PropTypes.number.isRequired,
  handleCartOpen: PropTypes.func.isRequired,
  hasHero: PropTypes.bool,
  translations: PropTypes.array,
}

MainHeader.defaultProps = {
  hasHero: false,
}

export default MainHeader
