import { createStore as reduxCreateStore } from 'redux'
import reducer from '../reducers'

const createStore = () =>
  reduxCreateStore(
    reducer
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )

export default createStore
