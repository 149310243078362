import React from 'react'
import PropTypes from 'prop-types'

const ShowMainNavIcon = ({ color }) => {
  return (
    <svg fill="none" height="11" viewBox="0 0 25 11" width="25">
      <g stroke={color} strokeWidth="2">
        <path d="m0 1h25" />
        <path d="m0 10h25" />
      </g>
    </svg>
  )
}

ShowMainNavIcon.propTypes = {
  color: PropTypes.string,
}

ShowMainNavIcon.defaultProps = {
  color: '#2D2D3F', // '#4a4a6c',
}

export default ShowMainNavIcon
