/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { withLocalize } from 'react-localize-redux'

/**
 * @param {Array} edges
 * @param {String} lang
 */
function getCurrLangEdge(edges, lang) {
  return edges.find(({ node }) => node.lang === lang)
}

export default WrappedComponent => {
  const hocComponent = ({ ...props }) => (
    <StaticQuery
      query={graphql`
        query withCategoriesDataQuery {
          allPrismicHomepage {
            edges {
              node {
                lang
                data {
                  categories {
                    category {
                      document {
                        ... on PrismicCategory {
                          id
                          data {
                            title {
                              text
                            }
                            subcategories {
                              subcategory {
                                id
                                document {
                                  ... on PrismicSubcategory {
                                    id
                                    data {
                                      title {
                                        text
                                      }
                                    }
                                    fields {
                                      pagePath
                                    }
                                  }
                                }
                              }
                            }
                          }
                          fields {
                            pagePath
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { activeLanguage } = props

        if (!activeLanguage) return null

        const currLangEdge = getCurrLangEdge(
          data.allPrismicHomepage.edges,
          activeLanguage.code
        )

        const categories = currLangEdge.node.data.categories.map(
          ({ category }) => {
            const { document } = category
            const {
              id,
              data,
              fields: { pagePath },
            } = document
            const {
              title: { text: name },
              subcategories: dataSubcategories,
            } = data
            const subcategories = dataSubcategories
              .filter(({ subcategory: { document } }) => !!document)
              .map(({ subcategory }) => {
                const {
                  document: {
                    id,
                    data: {
                      title: { text: name },
                    },
                    fields: { pagePath },
                  },
                } = subcategory
                return {
                  id,
                  name,
                  pagePath,
                }
              })
            return {
              id,
              name,
              pagePath,
              subcategories,
            }
          }
        )

        return <WrappedComponent categories={categories} {...props} />
      }}
    />
  )

  hocComponent.propTypes = {
    activeLanguage: PropTypes.object,
  }

  return withLocalize(hocComponent)
}
