/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { StaticQuery, graphql } from 'gatsby'

/**
 * @param {Array} nodes
 * @param {String} currLang
 */
function getCurrLangNodes(nodes, currLang) {
  return nodes.filter(({ lang }) => lang === currLang)
}

export default WrappedComponent => {
  const hocComponent = ({ ...props }) => {
    const { activeLanguage } = props
    if (!activeLanguage) return null

    const { code } = activeLanguage

    return (
      <StaticQuery
        query={graphql`
          query WithMainNavDataQuery {
            navData: allPrismicNavs {
              nodes {
                id
                lang
                data {
                  body {
                    ... on PrismicNavsBodyItem1 {
                      id
                      primary {
                        name
                        link {
                          ...PrismicNavDocumentData
                        }
                      }
                      slice_type: slice_type
                    }
                    ... on PrismicNavsBodyItem2 {
                      id
                      primary {
                        name
                        link {
                          ...PrismicNavDocumentData
                        }
                      }
                      slice_type: slice_type
                    }
                    ... on PrismicNavsBodyItem3 {
                      id
                      primary {
                        name
                        link {
                          ...PrismicNavDocumentData
                        }
                      }
                      slice_type: slice_type
                    }
                  }
                  footerColA: col_1 {
                    link {
                      ...PrismicNavDocumentData
                    }
                    name
                  }
                  footerColB: col_2 {
                    link {
                      ...PrismicNavDocumentData
                    }
                    name
                  }
                }
              }
            }
            legalPages: allPrismicLegalPage {
              nodes {
                id
                data {
                  title {
                    text
                  }
                }
                lang
                fields {
                  pagePath
                }
              }
            }
          }
        `}
        render={data => {
          const legalPages = getCurrLangNodes(data.legalPages.nodes, code)
          const navData = getCurrLangNodes(data.navData.nodes, code)

          if (!navData.length) return null

          return (
            <WrappedComponent
              legalPages={legalPages}
              navData={navData[0]}
              {...props}
            />
          )
        }}
      />
    )
  }

  hocComponent.propTypes = {
    activeLanguage: PropTypes.object,
  }

  return withLocalize(hocComponent)
}
