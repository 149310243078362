import { combineReducers } from 'redux'
import { lastSeen } from './lastSeen'
import { localizeReducer } from 'react-localize-redux'
import { mainNavIsVisible } from './mainNavIsVisible'
import { pageOverlayIsVisible } from './pageOverlayIsVisible'
import { shopifyClient } from './shopifyClient'

const reducers = combineReducers({
  lastSeen,
  localize: localizeReducer,
  mainNavIsVisible,
  pageOverlayIsVisible,
  shopifyClient,
})

export default reducers
