import React from 'react'
import PropTypes from 'prop-types'

const ChevronDown = ({ color }) => {
  return (
    <svg width="9" height="4" viewBox="0 0 9 4">
      <path
        d="M3.99865 3.99865C3.8652 3.99891 3.73588 3.95243 3.63312 3.86729L0.206323 1.01162C0.0896883 0.914678 0.0163408 0.775372 0.00241675 0.624349C-0.0115073 0.473327 0.0351325 0.322958 0.132076 0.206323C0.229019 0.089688 0.368325 0.0163408 0.519348 0.00241676C0.670371 -0.0115073 0.820739 0.0351323 0.937374 0.132076L3.99865 2.69075L7.05992 0.223457C7.11834 0.176016 7.18556 0.140587 7.25772 0.119209C7.32988 0.0978302 7.40555 0.090923 7.48038 0.0988842C7.55522 0.106845 7.62774 0.129517 7.69378 0.165598C7.75983 0.201679 7.81809 0.250457 7.86522 0.309127C7.91753 0.367852 7.95714 0.436746 7.98158 0.511492C8.00602 0.586238 8.01476 0.665226 8.00725 0.743508C7.99975 0.821789 7.97616 0.897677 7.93796 0.966417C7.89976 1.03516 7.84777 1.09527 7.78526 1.14298L4.35846 3.90156C4.25276 3.97324 4.12607 4.00743 3.99865 3.99865V3.99865Z"
        fill={color}
      />
    </svg>
  )
}

ChevronDown.propTypes = {
  color: PropTypes.string,
}

ChevronDown.defaultProps = {
  color: '#2D2D3F',
}

export default ChevronDown
