import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import SectionTitle, { TITLE_TYPES } from '../SectionTitle/SectionTitle'

import { form, msg as msgClassName } from './NewsletterForm.module.scss'

const getListFields = (lang = 'en-gb') => {
  switch (lang) {
    case 'es-es':
      return { 'group[22913]': '1' }
    case 'en-gb':
      return { 'group[22913]': '2' }
  }
}

const NewsletterForm = ({ translate, activeLanguage, onSubmit }) => {
  const [value, setValue] = useState('')
  const [msg, setMsg] = useState(null)

  const handleChange = event => {
    setValue(event.target.value)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    const listFields = getListFields(activeLanguage.code)
    const defaultMsg = translate('subscribeThanks')
    setMsg(defaultMsg)

    try {
      const { msg } = await addToMailchimp(value, listFields)
      setMsg(msg)
    } catch (error) {
      console.log(error)
    }

    if (window.gtag) window.gtag('event', 'newsletter_subscribe')

    document.cookie = 'newsletter_submitted=true;max-age=31536000'

    onSubmit()
  }

  if (!activeLanguage) return null

  return (
    <>
      <div
        className="-mt-3 lg:-mt-6"
        style={{ textShadow: '0 2px 4px rgba(0,0,0,0.075)' }}
      >
        <SectionTitle
          translationId="joinNow"
          type={TITLE_TYPES.HANDWRITTEN_ALPHA_WHITE}
        />
      </div>
      <div
        className="-mt-4 lg:-mt-10 text-center text-white-pure text-lg lg:text-xl xl:text-2xl tracking-tight leading-tight"
        style={{ textShadow: '0 2px 4px rgba(0,0,0,0.075)' }}
      >
        <Translate id="supportOurEcuadorianLegacy" />
      </div>
      <div
        className="font-sans-regular mb-5 text-center text-white-pure"
        style={{ textShadow: '0 2px 4px rgba(0,0,0,0.10)' }}
      >
        <Translate id="subscribeAndObtainDiscount" />
      </div>
      <form
        onSubmit={handleSubmit}
        className={`mx-auto max-w-sm text-xs lg:text-sm xl:text-base ${form}`}
      >
        <div className="flex shadow-lg">
          <input
            type="email"
            onChange={handleChange}
            value={value}
            className="flex-1 bg-white py-2 px-4 -ml-1 focus:outline-none"
            placeholder={translate('yourEmail')}
            required
          />
          <button type="submit" className="py-2 px-4 bg-terracotta">
            <Translate id="subscribe" />
          </button>
        </div>
        {msg && (
          <div
            className={`pt-3 text-white text-sm ${msgClassName}`}
            dangerouslySetInnerHTML={{ __html: msg }}
          />
        )}
      </form>
    </>
  )
}

NewsletterForm.propTypes = {
  translate: PropTypes.func,
  activeLanguage: PropTypes.object,
  onSubmit: PropTypes.func,
}

NewsletterForm.defaultProps = {
  translate: () => {},
  activeLanguage: null,
  onSubmit: () => {},
}

export default withLocalize(NewsletterForm)
