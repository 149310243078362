import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

export const TITLE_TYPES = {
  HANDWRITTEN_ALPHA: 'HANDWRITTEN_ALPHA',
  HANDWRITTEN_ALPHA_WHITE: 'HANDWRITTEN_ALPHA_WHITE',
  DEFAULT: 'DEFAULT',
}

const getClassNameForType = type => {
  switch (type) {
    case TITLE_TYPES.HANDWRITTEN_ALPHA:
      return 'mb-5 lg:mb-10 font-script text-4xl 2sm:text-5xl lg:text-6xl xl:text-7xl leading-none text-zinnwaldite text-center'
    case TITLE_TYPES.HANDWRITTEN_ALPHA_WHITE:
      return 'mb-5 lg:mb-10 font-script text-4xl 2sm:text-5xl lg:text-6xl xl:text-7xl leading-none text-white text-center'
    default:
      return 'mb-12 uppercase tracking-widest leading-none text-center text-xs md:text-sm'
  }
}

const getHtmlElement = (htmlElement, type, content) => {
  const elementProps = {
    className: getClassNameForType(type),
  }
  switch (htmlElement) {
    case 'h1':
      return <h1 {...elementProps}>{content}</h1>
    default:
      return <h2 {...elementProps}>{content}</h2>
  }
}

const SectionTitle = ({ translationId, text, type, htmlElement }) => {
  const content = (
    <>
      {translationId && <Translate id={translationId} />}
      {text}
    </>
  )
  return getHtmlElement(htmlElement, type, content)
}

SectionTitle.propTypes = {
  translationId: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  htmlElement: PropTypes.string,
}

SectionTitle.defaultProps = {
  type: TITLE_TYPES.DEFAULT,
  htmlElement: 'h2',
}

export default SectionTitle
