import { SET_PAGE_OVERLAY_VISIBILITY } from '../actions/pageOverlayVisibility'

export function pageOverlayIsVisible(state = false, action) {
  switch (action.type) {
    case SET_PAGE_OVERLAY_VISIBILITY:
      return action.visible
    default:
      return state
  }
}
