/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { withLocalize } from 'react-localize-redux'

/**
 * @param {Array} edges
 * @param {String} lang
 */
function getCurrLangEdges(edges, lang) {
  return edges.filter(({ node }) => node.lang === lang)
}

/**
 * @param {Array} edges
 * @param {String} sku
 */
function getDataBySku(edges, sku) {
  const { node } = edges.find(({ node }) => node.data.sku === sku) || {}

  if (!node) return {}

  const { data, fields } = node
  const { mainGallery, category } = data
  const { pagePath } = fields
  const categoryName = category.document.data.title.text

  const gallery = mainGallery
    .map(({ image: { localFile } }) => localFile)
    .filter(file => !!file)

  return {
    image: gallery[0],
    pagePath,
    categoryName,
  }
}

export default WrappedComponent => {
  const hocComponent = ({ ...props }) => (
    <StaticQuery
      query={graphql`
        query withPrismicProductDataQuery {
          allPrismicProduct {
            edges {
              node {
                id
                lang
                data {
                  mainGallery: main_gallery {
                    image {
                      localFile {
                        id
                        childImageSharp {
                          gatsbyImageData(
                            quality: 85
                            placeholder: NONE
                            layout: FULL_WIDTH
                          )
                        }
                      }
                    }
                  }
                  sku
                  category {
                    document {
                      __typename
                      ... on PrismicCategory {
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
                fields {
                  pagePath
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { activeLanguage, sku } = props

        if (!activeLanguage) return null

        const currLangEdges = getCurrLangEdges(
          data.allPrismicProduct.edges,
          activeLanguage.code
        )
        const { image, pagePath, categoryName } = getDataBySku(
          currLangEdges,
          sku
        )

        if (!image || !pagePath || !categoryName) {
          const { removeLineItemInCart, shopifyId } = props
          removeLineItemInCart(shopifyId)
          return null
        }

        return (
          <WrappedComponent
            image={image}
            pagePath={pagePath}
            categoryName={categoryName}
            {...props}
          />
        )
      }}
    />
  )

  hocComponent.propTypes = {
    activeLanguage: PropTypes.object,
    sku: PropTypes.string.isRequired,
  }

  return withLocalize(hocComponent)
}
