import React from 'react'
import PropTypes from 'prop-types'

const PinterestIcon = ({ color }) => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16">
      <path
        d="m7.99821 0c-4.41687 0-7.99821 3.58134-7.99821 7.99821 0 3.27429 1.97099 6.08789 4.78822 7.32699-.02143-.5571-.00357-1.2283.13925-1.8354.15354-.6498 1.02834-4.35969 1.02834-4.35969s-.25708-.51061-.25708-1.26401c0-1.18545.68556-2.07096 1.54251-2.07096.72841 0 1.07833.5463 1.07833 1.19973 0 .73198-.46775 1.82459-.70698 2.83865-.19996.84978.4249 1.53898 1.264 1.53898 1.51391 0 2.53511-1.94603 2.53511-4.25266 0-1.75318-1.1818-3.0636-3.32779-3.0636-2.42446 0-3.93841 1.81031-3.93841 3.83128 0 .69628.2071 1.18902.52846 1.56751.14639.17496.16782.24638.11426.44633-.03928.14644-.12498.49984-.16425.64274-.05356.2035-.21781.2749-.39991.1999-1.11761-.457-1.63892-1.67817-1.63892-3.05643 0-2.27092 1.91743-4.99531 5.71658-4.99531 3.05288 0 5.06318 2.21022 5.06318 4.58112 0 3.13862-1.7461 5.48092-4.31692 5.48092-.86409 0-1.67463-.4678-1.95314-.9962 0 0-.46418 1.8424-.56416 2.1995-.16782.6177-.49988 1.2319-.80339 1.7139.73623.2172 1.49976.3278 2.26735.3285 4.41686 0 7.99826-3.5813 7.99826-7.99821 0-4.41688-3.5778-8.00179-7.99469-8.00179z"
        fill={color}
      />
    </svg>
  )
}

PinterestIcon.propTypes = {
  color: PropTypes.string,
}

PinterestIcon.defaultProps = {
  color: '#2D2D3F',
}

export default PinterestIcon
