import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Modal from 'react-modal'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Link } from 'gatsby'
import useCollapse from 'react-collapsed'
import { Fade } from 'react-awesome-reveal'

import MainHeaderBrand from '../MainHeaderBrand/MainHeaderBrand'
import clearIconSrc from '../../icons/clear.svg'
import { overlay, content } from './MainNav.module.scss'
import withMainNavData from '../../hocs/withMainNavData'
import { setMainNavVisibility } from '../../actions/mainNavVisibility'
import withCategoriesData from '../../hocs/withCategoriesData'
import { buildNavDataTree } from '../../utils/buildNavDataTree'
import ChevronDownCircle from '../../icons/ChevronDownCircle'

const linkClassName = 'block pl-6 py-3'

const ListItem = ({ name, link, children, defaultExpanded = false }) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded,
  })
  const content = link ? (
    <Link to={`/${link}`} className={linkClassName}>
      {name}
    </Link>
  ) : (
    <span className={`${linkClassName} opacity-50`}>{name}</span>
  )

  return (
    <li>
      <div className="flex items-center">
        {content}
        {children.length > 0 && (
          <button
            type="button"
            className="ml-3 flex items-center justify-center focus:outline-none"
            style={{ transform: `rotate(${isExpanded ? '180deg' : '0deg'})` }}
            {...getToggleProps()}
          >
            <ChevronDownCircle />
          </button>
        )}
      </div>
      {children.length > 0 && (
        <ul
          className="uppercase leading-none tracking-widest text-xs pl-3"
          {...getCollapseProps()}
        >
          {children.map(
            ({
              id: childId,
              name: childName,
              link: childLink,
              children: innerChildren,
            }) => {
              return (
                <ListItem
                  key={childId}
                  name={childName}
                  link={childLink}
                  children={innerChildren}
                />
              )
            }
          )}
        </ul>
      )}
    </li>
  )
}

const MainNav = ({ mainNavIsVisible, hideNav, navData }) => {
  const items = buildNavDataTree(navData)

  return (
    <Modal
      isOpen={mainNavIsVisible}
      onAfterOpen={() => {
        disableBodyScroll()
      }}
      onAfterClose={() => {
        enableBodyScroll()
      }}
      onRequestClose={() => hideNav()}
      overlayClassName={`${overlay} fixed inset-0 font-sans`}
      className={`${content} fixed inset-0 bg-almond focus:outline-none z-10`}
      ariaHideApp={false}
      closeTimeoutMS={800}
    >
      <>
        <div className="flex items-center pl-8 md:hidden h-16">
          <MainHeaderBrand />
        </div>
        <div className="absolute inset-0 flex flex-col pt-16 md:pt-0">
          <div className="my-auto">
            <Fade cascade delay={300} duration={400}>
              <ul className="tracking-tight text-xl py-3 border-t border-b border-white">
                {items.map(({ id, name, link, children }) => {
                  return (
                    <ListItem
                      key={id}
                      name={name}
                      link={link}
                      children={children}
                      defaultExpanded
                    />
                  )
                })}
              </ul>
            </Fade>
          </div>
          <div className="mt-auto text-center pb-4">
            <a
              className="text-2xs uppercase tracking-widest"
              href="https://instagram.com/cabuyahandmade"
              rel="noreferrer noopener"
              target="_blank"
            >
              @cabuyahandmade
            </a>
          </div>
        </div>
        <div className="absolute top-0 right-0 z-10">
          <button
            type="button"
            className="w-16 h-16 flex items-center justify-center opacity-50 focus:outline-none focus:opacity-100"
            onClick={() => hideNav()}
          >
            <img src={clearIconSrc} alt="" />
          </button>
        </div>
      </>
    </Modal>
  )
}

MainNav.propTypes = {
  aboutPage: PropTypes.object,
  mainNavIsVisible: PropTypes.bool,
  hideNav: PropTypes.func,
  navData: PropTypes.object,
}

const mapStateToProps = ({ mainNavIsVisible }) => {
  return { mainNavIsVisible }
}

const mapDispatchToProps = dispatch => {
  return {
    hideNav: () => dispatch(setMainNavVisibility(false)),
  }
}

export default compose(
  withCategoriesData,
  withMainNavData
)(connect(mapStateToProps, mapDispatchToProps)(MainNav))
