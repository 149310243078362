import { combineReducers } from 'redux'
import { ADD_TO_LAST_SEEN } from '../actions/addToLastSeen'

/**
 * TODO: persist state
 * https://stackoverflow.com/a/35675304
 */

function allIds(state = [], action) {
  switch (action.type) {
    case ADD_TO_LAST_SEEN:
      return [action.id].concat(state.filter(curr => curr !== action.id))
    default:
      return state
  }
}

export const lastSeen = combineReducers({
  allIds,
})
