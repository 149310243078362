/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import Client from 'shopify-buy'

const client = Client.buildClient({
  storefrontAccessToken: '2ca72aa9cff29bc822e9f6c168f1fb13',
  domain: 'cabuya-handmade.myshopify.com',
})

export default WrappedComponent => {
  const hocComponent = ({ ...props }) => (
    <WrappedComponent client={client} {...props} />
  )

  return hocComponent
}
