import { SET_MAIN_NAV_VISIBILITY } from '../actions/mainNavVisibility'

export function mainNavIsVisible(state = false, action) {
  switch (action.type) {
    case SET_MAIN_NAV_VISIBILITY:
      return action.visible
    default:
      return state
  }
}
