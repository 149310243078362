import React from 'react'
import PropTypes from 'prop-types'

const Container = ({ children, hasMarginBottom }) => {
  return (
    <div className={`flex justify-center${hasMarginBottom ? ' mb-20' : ''}`}>
      <div className="w-full max-w-8xl px-6 xl:px-8">{children}</div>
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node,
  hasMarginBottom: PropTypes.bool,
}

Container.defaultProps = {
  children: null,
  hasMarginBottom: true,
}

export default Container
