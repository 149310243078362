/**
 * action types
 */

export const SET_PAGE_OVERLAY_VISIBILITY = 'SET_PAGE_OVERLAY_VISIBILITY'

/**
 * action creators
 */

/**
 * @param {Boolean} visible
 */
export function setPageOverlayVisibility(visible) {
  return {
    type: SET_PAGE_OVERLAY_VISIBILITY,
    visible,
  }
}
